<template>
    <div style="padding-top: 8em;">
        <header-service :title="'Detail Article'" :subtitle="'Portal Informasi Bisnis Legalpedia'"></header-service>
        <section>
            <v-container class="mt-4">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-3 mb-5">
            <v-container>
                <v-row>
                    <v-col class="col-12" md="12">
                        <header class="entry-header mh-clearfix">
                            <h1 class="entry-title">{{ article ? article.title : '' }}</h1>
                            <p class="mh-meta entry-meta">
                                <span class="mh-meta-date updated mr-3"><v-icon small>mdi-clock-outline</v-icon>{{ article ? article.created_at : '' }}</span>
                                <span class="mh-meta-author author vcard">
                                    <v-icon small class="mr-2">mdi-account</v-icon>
                                    <a class="fn" href="">{{ article ? article.author : '' }}</a>
                                </span>
                            </p>
                        </header>
                        <div class="entry-content mh-clearfix">
                            <figure class="entry-thumbnail">
                                <img class="img-fluid" :src="article ? article.image : ''" alt="" title="">
                            </figure>
                            <div v-html="article.description"></div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {article_api} from "@/backend-api/article_api"

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Article',
                disabled: false,
                href: '/article',
            },
            {
                text: 'Detail Article',
                disabled: true,
            }
        ],
        articles:[],
        page: 1,
        cycle: true,
        article: null
    }),
    methods:{
        async getDetail(){
          var reqBody = {
            'slug': this.$route.params.slug
          }
          const respData = await article_api.show('', reqBody, false, false, false)
          if (respData.status === 200) {
            this.article = respData.data.data
          }
        },
    },
    async mounted() { 
        await this.getDetail()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>